import axios from "axios"
import { getUserProfile, clearStorage } from "@/utils/authenticationManager"
import { canDebug } from "@/utils/utils"
import pendoManager from "@/utils/pendoManager"

const state = {
  isLoggedIn: false,
  user: null,
  crmIntegrations: {},
  crmDataLoading: false
}

export const getters = {
  isUserAuthenticated: state => {
    return state.isLoggedIn
  },
  user: state => {
    return state.user
  },
  isGlobalAdmin: state => {
    return state.user.permissions.is_global_admin
  },
  isAdmin: state => {
    return state.user.is_admin
  },
  isSuperUser: state => {
    return state.user.is_superuser
  },
  isAdminOrSuperUser: state => {
    return state.user.is_admin || state.user.is_superuser
  },
  crmIntegrations: state => {
    return state.crmIntegrations
  },
  isCrmDataLoading: state => {
    return state.crmDataLoading
  },
  getCrmIntegrationErrors: state => {
    if (state.crmIntegrations && state.crmIntegrations.errors) return state.crmIntegrations.errors
    return null
  },
  userPermissions: state => {
    return !!state.user &&
    state.user.permissions
      ? state.user.permissions
      : {}
  },
  userCrmFeatures: (state, getters) => {
    return getters.userPermissions.can_use_features
      ? getters.userPermissions.can_use_features
      : []
  },
  canUseSaveToCrmFeature: (state, getters) => {
    return getters.userCrmFeatures.includes("saveToCrm") && getters.userPermissions.allow_save_to_crm
  },
  canUseCrmEvent: (state, getters) => {
    return getters.userCrmFeatures.includes("createCrmEvent")
  },
  canUseCrmTask: (state, getters) => {
    return getters.userCrmFeatures.includes("taskcreator")
  },
  canUsePhoneCall: (state, getters) => {
    return getters.userCrmFeatures.includes("phone_call")
  },
  canUseMeeting: (state, getters) => {
    return getters.userCrmFeatures.includes("meeting")
  },
  canSendEmailThroughCRM: (state, getters) => {
    try {
      return getters.userCrmFeatures.includes("sendEmail")
    } catch (err) {
      console.debug(err)
    }
    return false
  },
  canUseBaoValue: (state, getters) => {
    try {
      return getters.userPermissions.can_use_bao_value
    } catch (err) {
      console.debug(err)
    }
    return false
  },
  canStoreCRMData: (state, getters) => {
    try {
      return getters.userPermissions.can_store_crm_data
    } catch (err) {
      console.debug(err)
    }
    return false
  },
  canUseCallResult: (state, getters) => {
    try {
      return getters.userPermissions.can_use_call_result
    } catch (err) {
      console.debug(err)
    }
    return false
  },
  canUsePsychologicalContent: (state, getters) => {
    // TODO: remove this and it's use in code because this feature is obsolete.
    try {
      return getters.userPermissions.can_use_psychological_content
    } catch (err) {
      console.debug(err)
    }
    return false
  },
  canUseBaoCrm: state => {
    return !!state.crmIntegrations && !!state.crmIntegrations.bao
  },
  canUseCRM (state) {
    return !!state.crmIntegrations && Object.keys(state.crmIntegrations).length > 0
  },
  canUseAirCall: state => {
    return !!state.crmIntegrations && !!state.crmIntegrations.aircall // todo: rename crmIntegrations to integrations as integrated service may not necessarily be a CRM service.
  },
  showTextMatch: (state, getters) => {
    try {
      return (
        !!getters.userPermissions.speech_recognition &&
        !!getters.userPermissions.speech_recognition.show_text_match
      )
    } catch (err) {
      console.debug(err)
    }
    return false
  },
  canUseSpeechRecognition: (state, getters) => {
    try {
      return (
        !!getters.userPermissions.speech_recognition &&
        !!getters.userPermissions.speech_recognition.can_use_speech_recognition
      )
    } catch (err) {
      console.debug(err)
    }
    return false
  },
  canUseBaoAudio: (state, getters) => {
    try {
      return getters.userPermissions.can_use_bao_audio
    } catch (err) {
      console.debug(err)
    }
    return false
  },
  userAudioInputLanguage: state => {
    return state.user && state.user.audio_input_language ? state.user.audio_input_language : null
  },
  canUseBaoCoPilot (state, getters) {
    return getters.userPermissions.can_use_bao_co_pilot
  },
  canUseVideoIntegration (state, getters) {
    return getters.userPermissions.can_use_video_integration
  },
  canUseCalendarIntegration (state, getters) {
    return getters.userPermissions.can_use_calendar_integration
  },
  canUseLiveTranscription (state, getters) {
    return getters.userPermissions.can_use_live_transcription
  },
  canFilterOnUserBasis (state, getters) {
    return getters.userPermissions.can_filter_on_user_basis
  },
  canShowEncouragingMessage (state) {
    return (
      !!state.user &&
      !!state.user.show_celebration_theme
    )
  },
  canModifyCallsRealTag (state, getters) {
    return getters.userPermissions.can_modify_calls_real_tag
  }
}

export const mutations = {
  login (state) {
    state.isLoggedIn = true
  },
  logout (state) {
    state.loggedIn = false
    state.user = {}
    clearStorage()
  },
  setUser (state, payload) {
    state.user = payload
    if (!canDebug) {
      pendoManager.registerUser(payload)
    }
  },
  setCRMIntegrations (state, payload) {
    state.crmIntegrations = payload
  },
  setCrmDataLoading (state, value) {
    state.crmDataLoading = value
  }
}

export const actions = {
  getUser: async ({ commit, dispatch }) => {
    // TODO: Keep the following if state is store aware
    // const userProfile = state.user
    // if (userProfile) {
    //   return Promise.resolve(userProfile)
    // }
    try {
      dispatch("getCrmIntegrationsForUser")
      await getUserProfile().then(userProfile => {
        commit("login")
        commit("setUser", userProfile)
        // send message to bao swift to animate swift with value userProfile.animate_bao_swift
        dispatch("sendMessageToBaoSwift", { type: "animateBaoSwift", value: !!userProfile.animate_bao_swift })
      })
    } catch (error) {
      console.log(error)
      commit("logout")
      return Promise.reject(error)
    }
  },
  getCrmIntegrationsForUser ({ commit }) {
    commit("setCrmDataLoading", true)
    return new Promise((resolve, reject) => {
      axios.get("/api/users/get_integrated_crms").then(response => {
        commit("setCrmDataLoading", false)
        commit("setCRMIntegrations", response.data)
        resolve(response)
      }).catch(error => {
        commit("setCrmDataLoading", false)
        reject(error)
      })
    })
  },
  sendMessageToBaoSwift ({ _ }, message) {
    if (window.parent && window.parent.postMessage) {
      window.parent.postMessage(message, "*")
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
