<template>
  <div class="base-form-textarea"
       :style="cssVars"
  >
    <!-- label   -->
    <label v-if="label"
           class="base-form-textarea__label"
    >
      {{ label }}
    </label>

    <!-- Placeholder   -->
    <span v-if="!!placeholder && showPlaceholder"
          class="base-form-textarea__placeholder text-muted"
          @click="focusInput()"
    >{{ placeholder }}
    </span>

    <!-- Text Input   -->
    <div :id="_getUniqueId()"
         ref="custom-text-input"
         :class="textAreaClass"
         :contenteditable="!isDisabled"
         spellcheck="false"
         @keydown.enter="event=>_insertLineBreak(event)"
         @blur="event =>onBlur(event)"
         @input="showPlaceholder = false"
    >
      <span v-if="!showPlaceholder"
      >
        {{ internalValue }}
      </span>
    </div>

  </div>
</template>

<script>

export let RUNNING_INDEX = 0
export default {
  name: "BaseFormTextarea",

  data () {
    return {
      internalValue: "",
      initialTextContent: "",
      showPlaceholder: true
    }
  },

  props: {
    inputId: {
      type: [String, Number],
      default: null
    },
    value: {
      type: [String, Number],
      default: null
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    label: {
      required: false,
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    maxRows: {
      type: Number,
      default: 6
    }
  },
  watch: {
    value (val) {
      this.updateContentEditable(val)
      this.showPlaceholder = !(this.value && this.value.trim().length > 0)
    }
  },
  mounted () {
    if (this.value && this.value.trim().length > 0) {
      this.showPlaceholder = false
      this.updateContentEditable(this.value)
    }
  },
  computed: {
    cssVars () {
      return {
        /* variables you want to pass to css */
        "--maxHeight": `${this.maxRows * 24}px`,
        "--padding": this.label ? "22px 16px 10px 16px" : "16px"

      }
    },
    textAreaClass () {
      return ["base-form-textarea__input", { "base-form-textarea__disabled": this.isDisabled }]
    }
  },

  methods: {
    _insertLineBreak (event) {
      event.preventDefault()
      const inputElement = this.$refs["custom-text-input"]
      const selection = window.getSelection()
      const range = selection.getRangeAt(0)

      // Create a <br> element
      const br = document.createElement("br")

      // Insert the <br> element at the caret position
      range.insertNode(br)

      // Move the caret after the <br>
      range.setStartAfter(br)
      range.setEndAfter(br)

      // Ensure the selection is updated
      selection.removeAllRanges()
      selection.addRange(range)

      // Prevent the need for pressing Enter twice
      // Add another <br> if the caret is at the end of the content
      const lastNode = inputElement.lastChild
      if (lastNode && lastNode.nodeName !== "BR") {
        inputElement.appendChild(document.createElement("br"))
      }
    },
    _getUniqueId () {
      /**
       * Returns a new unique id based on the current datetime and an incremental index
       * */
      RUNNING_INDEX += 1
      return this.inputId || "textarea" + Date.now() + "-" + RUNNING_INDEX
    },
    onBlur (event) {
      const innerText = event.target.innerText
      const textContent = event.target.textContent.trim()
      this.showPlaceholder = textContent.length < 1
      if (this.initialTextContent.trim() !== textContent) {
        const payload = textContent.length < 1 ? textContent.trim() : innerText
        this.$emit("input", payload)
      }
    },
    focusInput () {
      this.$refs["custom-text-input"].focus()
    },
    updateContentEditable (innerText) {
      const inputElement = this.$refs["custom-text-input"]
      if (inputElement) {
        inputElement.innerText = innerText
        this.initialTextContent = inputElement.textContent
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ai-answer {
  padding-right: unset !important;
}

.base-form-textarea {
  min-height: 56px;
  min-width: 100px;
  position: relative;
  display: block;
  border-radius: 12px;
  background-color: #fff;
  font-size: 16px;
  border: 1px solid #fff;
  box-shadow: 6px 8px 20px rgba(179, 173, 159, 0.12);

  &__placeholder,
  &__input,
  &__disabled {
    border-radius: 8px;
  }

  &__placeholder {
    display: inherit;
    margin-bottom: -56px;
    padding: var(--padding);
  }

  &__input,
  &__disabled {
    overflow-y: scroll;
    max-height: var(--maxHeight);
    padding: var(--padding);
  }

  &__input {
    outline: none;
    width: auto;
  }

  &__label {
    position: absolute;
    top: 8px;
    left: 0;
    padding-left: 16px;
    z-index: 3;
    font-size: 12px;
    line-height: 14px;
    margin: 0;
    color: $slate60;
  }
}

</style>
