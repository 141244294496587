<template>
  <div :class="getCommentClass">
    <div class="mr-1">
      <b-avatar
        :text="getShortName(authorName)"
        class="avatar"
      />
    </div>
    <div
      v-if="comment.is_deleted"
      class="d-flex flex-column px-3 py-3 deleted-comment w-100"
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:height="'16px'" v-bind:width="'16px'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.333 8.67h5.333a.646.646 0 00.476-.193.642.642 0 00.191-.474.648.648 0 00-.192-.475.643.643 0 00-.475-.192H5.333a.643.643 0 00-.475.192.648.648 0 00-.192.475c0 .188.064.346.192.475a.64.64 0 00.475.191zm2.667 6a6.487 6.487 0 01-2.6-.526 6.743 6.743 0 01-2.117-1.425 6.718 6.718 0 01-1.425-2.116 6.506 6.506 0 01-.525-2.6c0-.922.175-1.789.525-2.6a6.748 6.748 0 011.425-2.117c.6-.6 1.305-1.075 2.117-1.425A6.494 6.494 0 018 1.336c.921 0 1.788.175 2.6.525.812.35 1.517.825 2.116 1.425.6.6 1.075 1.305 1.426 2.117.35.811.526 1.678.524 2.6a6.536 6.536 0 01-.525 2.6 6.688 6.688 0 01-1.425 2.116c-.6.6-1.306 1.075-2.116 1.426-.81.35-1.677.525-2.6.524zm0-1.334c1.489 0 2.75-.517 3.783-1.55s1.55-2.295 1.55-3.783c0-1.49-.517-2.75-1.55-3.784C10.75 3.186 9.489 2.67 8 2.67c-1.49 0-2.75.517-3.784 1.55-1.033 1.034-1.55 2.295-1.55 3.784 0 1.488.517 2.75 1.55 3.783 1.034 1.033 2.295 1.55 3.784 1.55z" fill="#BC0000" fill-opacity=".8"/></svg>
      <div class="mt-3 delete-text">
        {{ staticText.deletedMsgLabel }}
      </div>
    </div>
    <div
      v-else
      class="d-flex flex-column comment-box w-100 overflow-hidden"
    >
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="comment-details">
          <span class="author-name">{{ authorName }}</span>
          <span class="mx-2 custom-at">{{ staticText.atLabel }}</span>
          <b-button
            pill
            variant="primary"
            class="pill-btn"
          >
            <template>
              <div class="d-flex px-1 py-1">
                <span class="pill-title">{{ commentedAtInConversation }}</span>
              </div>
            </template>
          </b-button>
        </div>

        <div class="custom-buttons">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" :id="`edit-icon-${comment.id}-202411150410`" v-bind:height="'16px'" v-bind:width="'16px'" v-bind:class="'cursor-pointer'" @click="$emit('edit', comment)" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.56 3.727l2.603 2.604-6.59 6.591-2.603-2.604 6.59-6.59zm4.579-.628l-1.161-1.16a1.152 1.152 0 00-1.628 0L10.238 3.05l2.604 2.604 1.297-1.297a.888.888 0 000-1.258zM1.607 13.98a.296.296 0 00.359.352l2.901-.704-2.602-2.604-.658 2.955z" fill="#fff" fill-opacity=".8"/></svg>
          <b-tooltip
            :target="`edit-icon-${comment.id}-202411150410`"
            triggers="hover"
            custom-class="custom-tooltip"
          >
            <span class="px-2">{{staticText.editLabel}}</span>
          </b-tooltip>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" :id="`delete-icon-${comment.id}-202411150411`" v-bind:height="'16px'" v-bind:width="'16px'" v-bind:class="'ml-2 cursor-pointer'" @click="$emit('delete', comment)" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.2 12.897c0 .9.717 1.633 1.6 1.633h6.4c.882 0 1.6-.732 1.6-1.633V4.73H3.2v8.167zM9.6 2.281v-.016a.8.8 0 00-.8-.8H7.2a.8.8 0 00-.8.8v.016H3.2a.8.8 0 00-.8.8v.834h11.2v-.833a.8.8 0 00-.8-.8H9.6z" fill="#fff" fill-opacity=".8"/></svg>
          <b-tooltip
            :target="`delete-icon-${comment.id}-202411150411`"
            triggers="hover"
            custom-class="custom-tooltip"
          >
            <span class="px-2">{{staticText.deleteLabel}}</span>
          </b-tooltip>
        </div>
      </div>
      <div
        class="mt-2"
        :class="{'current-user-comment': comment.isCurrentUser}"
      >
        {{ commentText }}
      </div>
      <div class="d-flex align-items-center justify-content-between mt-2">
        <div v-if="!comment. isChildComment">
          <img
            :id="`reply-icon-${comment.id}-202411150412`"
            :src="getReplyIcon"
            height="16px"
            width="16px"
            class="cursor-pointer"
            @click="$emit('reply', comment)"
            svg-inline
          />
          <b-tooltip
            :target="`reply-icon-${comment.id}-202411150412`"
            triggers="hover"
            custom-class="custom-tooltip"
          >
            <span class="px-2">{{staticText.replyLabel}}</span>
          </b-tooltip>
        </div>
        <div class="commented-at d-flex flex-fill justify-content-end">
          <span
            v-if="comment.is_edited"
            class="mr-1"
          >
            {{staticText.editedLabel}} •
          </span>
          {{ formatDate(commentedAt) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "SingleComment",
  props: {
    comment: {
      type: Object,
      required: true
    },
    authorName: {
      type: String,
      required: true
    },
    commentedAtInConversation: {
      type: String,
      required: true
    },
    commentedAt: {
      type: String,
      required: true
    },
    commentText: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isHovered: false,
      staticTextDefault: {
        deletedMsgLabel: "This message was deleted.",
        atLabel: "at",
        editLabel: "Edit",
        deleteLabel: "Delete",
        replyLabel: "Reply",
        editedLabel: "Edited"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    getCommentClass () {
      const defaultClass = "single-comment"
      if (this.comment.isCurrentUser) {
        return `${defaultClass} current-user-comment`
      } else {
        return defaultClass
      }
    },
    getReplyIcon () {
      if (this.comment.isCurrentUser) {
        return require("@/assets/svgs/reply-2.svg")
      } else {
        return require("@/assets/svgs/reply.svg")
      }
    }
  },
  methods: {
    getShortName (name) {
      return name
        .split(" ")
        .map(word => word[0].toUpperCase())
        .slice(0, 2)
        .join("")
    },
    formatDate (dateString, datetype = false) {
      let date = dateString
      if (!datetype) {
        date = new Date(dateString)
      }
      return new Intl.DateTimeFormat("en-US", {
        day: "numeric",
        month: "short",
        hour: "numeric",
        minute: "numeric",
        hour12: true
      }).format(date)
    }
  }
}
</script>
<style scoped lang="scss">

.single-comment {
  display: flex;

  .avatar {
    background-color: #2A2D520F;
    color: #FF5632;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.4px;
  }

  .comment-box {
    padding: 12px;
    border-radius: 8px;
    background-color: #2A2D520F;

    .comment-details {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .custom-buttons {
      display: none;
    }
  }

  .author-name {
    font-size: 14px;
    font-weight: 700;
    line-height: 16.8px;
    color: #2A2D52;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .custom-at {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: #555775;
  }

  .pill-btn {
    padding: 0;
    background-color: #FF56321F;
  }

  &.current-user-comment {
    .avatar {
      background-color: #2A2D52;
      color: #EFEFEF;
    }

    .comment-box {
      background-color: #2A2D52;

      &:hover {
        .comment-details {
          width: calc(100% - 48px);
        }

        .custom-buttons {
          width: 40px;
          display: flex;
          align-items: center;
          margin-left: 4px;
        }
      }
    }

    .author-name {
      color: #FFFFFF;
    }

    .custom-at {
      color: #FFFFFFCC;
    }

    .pill-btn {
      padding: 0;
      background-color: #FFEAE6;
    }
  }
}
.pill-title{
    font-size: 12px;
    line-height: 14.4px;
    font-weight: 400;
    color: #FF5632;
}

.commented-at {
    color: #AAABBA;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
}

.current-user-comment {
    color: #FFFFFFCC;
}
.deleted-comment {
    background-color: #BC00001A;
    border-radius: 8px;
}
.delete-text {
    color: #BC0000CC;
    font-size: 14px;
    line-height: 16.8px;
    font-weight: 400;
}
.custom-tooltip{
    :deep(.tooltip-inner){
      padding: 0 !important;
    }
    :deep(.tooltip .tooltip-inner){
      padding: 0 !important;
    }
}

</style>
