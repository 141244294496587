<template>
  <div>
    <b-list-group-item v-for="(item, index) in items"
                       :key="index"
                       :href="'#' + item.uniqueId"
                       class="p-0 no-border"
                       :class="getGroupItemClasses(item)"
                       @click="event => clickedItem(item, event)"
    >
      <div class="item-title" :class="!isActiveItem(item) ? 'inactive' : ''">
        <div class="d-flex justify-content-between px-2">
          <div class="text-truncate--custom">
            <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="isItemAnswered(item)" v-bind:class="'mr-1'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.6.594a.644.644 0 010 .934l-5.486 5.28a.706.706 0 01-.97 0L.4 4.167a.643.643 0 010-.935.706.706 0 01.97 0L3.63 5.405l5-4.811a.706.706 0 01.971 0H9.6z" fill="#5D514B" fill-opacity=".4"/></svg>
            {{ index + 1 }}.

            <span :id="'nav-asterisks-202102191347-'+item.uniqueId"
                  v-if="item.required"
                  class="color-red"
            >
              *
            </span>
            {{ item.name }}
          </div>
        </div>

      </div>

      <div v-if="getLoadedADCs(item).length>0">
        <b-list-group-item v-for="(adc, index) in getLoadedADCs(item)"
                           :key="index"
                           :href="'#' + item.uniqueId + '-loadedData-' + index"
                           :class="getADCClasses(adc)"
                           class="sub-group-item sub-container"
                           a
                           @click="event => toggleADC(adc, event)"
        >
          <div v-if="adc.selected_choice"
               class="group-header pb-2 pl-2 pt-2"
          >
            <i :class="getADCClassForIcon(adc)"></i>
            {{ adc.selected_choice.name }}
          </div>

          <b-collapse :id="'item-' + item.id + '-adc-' + adc.selected_choice.id"
                      v-if="ADCHasChildren(adc)"
                      v-model="adc.open"
          >
            <call-navigation :items="adc.selected_choice.workflow.children"></call-navigation>
          </b-collapse>
        </b-list-group-item>
      </div>
    </b-list-group-item>
  </div>
</template>

<script>
import { currentCallStore } from "@/store/services/callStore"

export default {
  name: "CallNavigation",
  props: {
    items: { // callItem
      type: Array,
      required: true
    }
  },
  data: () => {
    return {
      config: {
        element: "#scrollspy-nested",
        offset: 0,
        method: "position",
        throttle: 0
      },
      rerender: false
    }
  },
  methods: {
    isItemAnswered (item) {
      return currentCallStore.isItemAnswered(item)
    },
    isActiveItem (item) {
      return currentCallStore.activeItem === item
    },
    activateItem (item) {
      currentCallStore.activateItem(item)
    },
    getADCClasses (adc) {
      return this.hasActiveChildren(adc) ? "active-group" : "inactive-group"
    },
    getADCClassForIcon (adc) {
      return adc.selected_choice && adc.selected_choice.type === "default"
        ? "mr-2 fas fa-book"
        : "mr-2 fas fa-bolt"
    },
    toggleADC (adc, event) {
      adc.open = !this.hasActiveChildren(adc) || !adc.open
      try {
        this.clickedItem(adc.selected_choice.workflow.children[0], event)
      } catch (error) {
        console.debug(error)
      }
      if (event) event.stopPropagation()
    },
    hasActiveChildren (adc) {
      let hasActiveChildren = false
      try {
        hasActiveChildren = adc.selected_choice.workflow.children.some(item => this.isActiveItem(item))
      } catch (error) {
        console.debug(error)
      }
      return hasActiveChildren
    },
    getGroupItemClasses (item) {
      let classes = "group-item "
      if (this.isActiveItem(item)) classes += "active "
      else if (this.isItemAnswered(item)) classes += "inactive "
      return classes
    },
    getLoadedADCs (item) {
      return item.loadedData ? item.loadedData : []
    },
    clickedItem (item, event) {
      item.open = true
      this.activateItem(item)
      // TODO: comment why was this done
      if (event) event.stopPropagation()
    },
    ADCHasChildren (adc) {
      return adc.selected_choice.workflow && adc.selected_choice.workflow.children
    }
  }
}
</script>

<style scoped lang="scss">
.group-item {
  border-radius: 12px;
  overflow: hidden;
  background: inherit;
  color: inherit;
  transition: background-color 0.3s ease-in;

  &.active {
    color: $white;
    background-color: $brown;

    .item-title {
      cursor: default;
    }
  }

  &.inactive {
    color: $browngray;
  }
}

.item-title {
  padding: 6px 4px;
  transition: background-color 0.1s ease-in;
  border-radius: 12px;

  &.inactive {
    cursor: pointer !important;

    &:hover {
      color: $brown;
      background-color: $brownlight;
    }
  }
}

.sub-group-item {
  padding: 0.35rem 0 0.35rem 0.2rem;
  border-radius: 12px;
  border: none;
}

.sub-container {
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 3px;
  background: inherit;
  color: inherit;
}

.active-group {
  background-color: inherit;
  color: $brown;
  border-radius: 12px;
  border: 1px solid;
}

.text-truncate--custom {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    all: unset;
  }
}
</style>
