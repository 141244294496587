<template>
  <div class="d-flex flex-column h-100 overflow-hidden p-3">
    <!-- Title -->
    <div class="title mt-3 mt-lg-5 mb-2">
      {{ staticText.conversationSetupLabel }}
    </div>

    <!-- Error display -->
    <div v-if="!!error">
      <b-alert :show="!!error"
               variant="danger"
               dismissible
               @dismissed="resetError"
      >
        {{ staticText.errorAlertLabel }}:
        <span v-html="error.data"></span>
      </b-alert>
    </div>

    <div class="row overflow-hidden">
      <!-- calendar event, counterparts and playbook section -->
      <div
        id="call-setup-left-block"
        class="col d-flex flex-column h-100"
      >
        <div class="main-container">
          <!-- calendar events section -->
          <calendar-event-block
            v-if="canUseVideoIntegration && canUseCalendarIntegration && calendarIntegrationGoogleAccount"
            @setMeetingUrl="setMeetingUrl"
          />
          <!-- counterparts section -->
          <call-counterparts-block
            :counterparts="counterparts"
            :call-setup-left-block-height="callSetupLeftBlockHeight"
            @set-counterparts="setCounterparts"
            @unselect-counterpart="index => removeCounterpart(index)"
          />

          <!-- playbooks section -->
          <call-playbook-block
            class="d-flex flex-column"
            @start-call="startBaoCall"
          />
        </div>
      </div>

      <!-- dashboard favorite page display -->
      <div class="col-5 d-none d-md-flex flex-column h-100">
        <div
          v-if="!!widgetPage"
          class="mb-2 d-flex align-items-center"
        >
          <span class="widgetPageTitle"> {{ staticText.myCallInsights }}</span>
          <span class="d-flex align-items-center"
          >
            <img
              id="new-conversation-widget-page-info-202207210047"
              class="ml-1 cursor-pointer"
              src="img/icons/info-icon.svg"
            />
            <b-tooltip
              id="tooltip-new-conversation-widget-page-info-202207210047"
              target="new-conversation-widget-page-info-202207210047"
            >
              <div class="text-left tooltip-text">
                {{ staticText.widgetPageInfo }}
                <b-btn id="show-widget-page-202207210047"
                       variant="primary"
                       class="mt-2"
                       @click="goToWidgetPage"
                >
                  {{ staticText.goToDashboard }}
                </b-btn>
              </div>
            </b-tooltip>
          </span>
        </div>
        <bao-dashboard-front-page
          :widget-page="widgetPage"
          class="widgets-div pl-0"
        ></bao-dashboard-front-page>
      </div>
    </div>

  </div>
</template>

<script>
import counterpartApp from "../counterpart"
import { mapActions, mapGetters, mapMutations } from "vuex"
import BaoDashboardFrontPage from "@/apps/dashboard/BaoDashboardComponents/BaoDashboardFrontPage"
import CallCounterpartsBlock from "./CallCounterpartsBlock.vue"
import CallPlaybookBlock from "./CallPlaybookBlock.vue"
import CallSetupMixin from "./CallSetupMixin"
import CalendarEventBlock from "@/apps/call/meeting/CalendarEventBlock.vue"
import { getUserCalendar } from "@/apps/user/integrations/api"

export default {
  name: "CallSetup",
  mixins: [CallSetupMixin],
  components: {
    BaoDashboardFrontPage,
    CallCounterpartsBlock,
    CallPlaybookBlock,
    CalendarEventBlock
  },
  data () {
    return {
      counterpartApp,
      error: null,
      counterpart: null,
      meetingUrl: null,
      staticTextDefault: {
        conversationSetupLabel: "New Conversation",
        errorAlertLabel: "Error",
        myCallInsights: "My Call Insights",
        widgetPageInfo: "These are the call widgets that you have created on the dashboard page. You can edit the page and all widgets via the dashboard page.",
        goToDashboard: "Go to Dashboard"
      },
      widgetPage: null,
      callSetupLeftBlockHeight: 0,
      resizeObserver: null,
      calendarIntegrationGoogleAccount: null
    }
  },
  computed: {
    ...mapGetters({
      getWidgetPage: "dashboardStore/getFavoritePage",
      canUseCalendarIntegration: "auth/canUseCalendarIntegration",
      canUseVideoIntegration: "auth/canUseVideoIntegration",
      user: "auth/user"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  async created () {
    this.widgetPageSetUp()
    this.setIsCallSetUpPage(true)
    await this.setUpCallFromUrl()
    this.triggerCounterpartAutoFillIfApplicable()
  },
  mounted () {
    this.getUserCalendarAuthData()
    this.initializeResizeObserver()
  },
  methods: {
    ...mapMutations({
      setIsCallSetUpPage: "callSetUpStore/setIsCallSetUpPage"
    }),
    ...mapActions({
      loadAnalyticsPages: "dashboardStore/loadAnalyticsPages"
    }),
    async getUserCalendarAuthData () {
      const calendarData = await getUserCalendar({ user_id: this.user.id })
      if (calendarData && calendarData.data && calendarData.data.length) {
        this.calendarIntegrationGoogleAccount = calendarData.data[0].email
      }
    },
    setMeetingUrl (data) {
      this.meetingUrl = data
    },
    startBaoCall (data) {
      let urlQuery = {}
      if (this.meetingUrl) urlQuery = { meetingUrl: this.meetingUrl }
      this.startCall(data, urlQuery)
    },
    initializeResizeObserver () {
      const callSetupLeftBlock = document.getElementById("call-setup-left-block")
      this.resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          this.callSetupLeftBlockHeight = entry.target.clientHeight
        }
      })
      this.resizeObserver.observe(callSetupLeftBlock)
    },
    widgetPageSetUp () {
      return new Promise((resolve, reject) => {
        this.loadAnalyticsPages().then(() => {
          this.widgetPage = this.getWidgetPage
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
    resetError () {
      this.error = null
    },
    goToWidgetPage () {
      this.$router.replace("/dashboard/" + this.widgetPage.id)
    },
    triggerCounterpartAutoFillIfApplicable () {
      if (this.isApplicationInIFrame && (!this.counterparts || !this.counterparts.length)) {
        this.retrieveIFrameHostUrl()
      }
    }
  },
  beforeDestroy () {
    this.setCounterparts([])
    this.setIsCallSetUpPage(false)
    this.resizeObserver.disconnect()
  }
}
</script>

<style scoped lang="scss">

.widgets-div {
  overflow-y: auto;
  padding-right: 8px;
}

.tooltip-text {
  color: $slate80;
  font-size: 14px;
  padding: 0.25rem;
}

.widgetPageTitle {
  font-weight: 700;
  font-size: 16px;
}

.tooltip.b-tooltip {
  opacity: 1 !important;
}
.main-container{
  overflow-y: auto;
  scrollbar-width: none;
}
</style>
