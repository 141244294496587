<template>
  <div class="call-comments">
    <progress-indicator
      v-if="commentsLoading"
      :loadingLabel="staticText.commentLoadingLabel"
    />

    <div
      v-else
      class="h-100 d-flex flex-column overflow-hidden w-100"
    >
      <div class="comment-header">
        <div class="d-flex align-items-center justify-content-between p-3">
          <div class="d-flex align-items-center">
            <img
              src="@/assets/svgs/comment.svg"
              height="20px"
              width="20px"
            >
            <span class="ml-2 headline">{{staticText.commentLabel}}</span>
          </div>
          <img
            src="@/assets/svgs/cross-icon.svg"
            height="16px"
            width="16px"
            style="cursor: pointer"
            @click="$emit('toggleCallComment')"
          />
        </div>
      </div>
      <!-- comments box -->
      <div class="comment-box-body d-flex flex-column overflow-hidden justify-content-between">
        <div
          v-if="commentsList.length"
          class="comment-box px-1 pt-1"
        >
          <comment
            v-for="comment in commentsList"
            :key="comment.id"
            :comment="comment"
            :authorName="comment.author_name"
            :commentedAtInConversation="comment.timestamp_in_media"
            :commentedAt="comment.is_edited ? comment.updated_at : comment.created_at"
            :commentText="comment.comment_text"
            @reply="replyToComment"
            @edit="initiateCommentEditing"
            @delete="deleteComment"
            class="single-comment"
            :class="{'child-class': comment.isChildComment}"
          />
        </div>
        <div
          v-else
          class="d-flex flex-column align-items-center justify-content-center no-comment-box"
        >
          <img
            src="@/assets/svgs/big-comment.svg"
            height="48px"
            width="48px"
          />
          <span class="no-comment mt-3">
            {{ staticText.noCommentMsg }}
          </span>
        </div>
        <!-- type comment -->
        <div class="mt-2">
          <div
            v-if="showReplyOrEditView"
            class="d-flex justify-content-between align-items-center edit-reply"
          >
            <div
              v-if="selectedParentId"
              class="comment-preview"
            >
              <span class="comment-preview__title">{{ user.full_name }}</span>
              <span class="comment-preview__subtitle mt-2">{{ parentMessage }}</span>
            </div>
            <div v-else class="preview-title py-1">
              {{ staticText.editingLabel }}
            </div>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:height="'16px'" v-bind:width="'16px'" v-bind:class="'cursor-pointer ml-2'" @click="cancelEditOrReply" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 1l10 10M1 11L11 1" stroke="#7F8197" stroke-width="2" stroke-linecap="round"/></svg>
          </div>
          <div class="comment-input d-flex align-items-center pr-2">
            <b-form-input
              :id="getInputId"
              v-model="currentComment"
              :placeholder="staticText.yourCommentLabel"
              class="custom-input"
              @keyup.enter="createComment"
            />
            <img
              :src="getSendIcon"
              height="20px"
              width="20px"
              @click="createComment"
              style="cursor: pointer"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Comment from "./SingleComment.vue"
import {
  createCallCommentApi, getCallCommentsApi, updateCallCommentApi,
  deleteCallCommentApi
} from "@/apps/call_history/api"
import ProgressIndicator from "@/apps/base/ProgressIndicator"

export default {
  name: "CallComments",
  components: {
    Comment,
    ProgressIndicator
  },
  props: {
    mediaType: {
      type: String,
      required: true
    },
    callId: {
      type: Number,
      required: true
    },
    timestampInMedia: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      currentComment: "",
      selectedParentId: null,
      parentMessage: "",
      showReplyOrEditView: false,
      targetItem: null,
      commentsList: [],
      editItem: null,
      commentsLoading: false,
      staticTextDefault: {
        commentLabel: "Comments",
        noCommentMsg: "No comments yet",
        editingLabel: "Editing",
        yourCommentLabel: "Your comment...",
        commentLoadingLabel: "Loading…"
      }
    }
  },
  mounted () {
    this.loadCallComments()
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    getInputId () {
      return `comment-input-${this.mediaType}`
    },
    getSendIcon () {
      if (this.currentComment) {
        return require("@/assets/svgs/send-2.svg")
      } else {
        return require("@/assets/svgs/send.svg")
      }
    }
  },
  methods: {
    formatTime (seconds) {
      const hours = Math.floor(seconds / 3600)
      const minutes = Math.floor((seconds % 3600) / 60)
      const remainingSeconds = Math.floor(seconds % 60)
      if (hours) {
        return `${hours.toString().padStart(2, "")}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`
      } else {
        return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`
      }
    },
    cancelEditOrReply () {
      this.parentMessage = ""
      this.selectedParentId = null
      this.showReplyOrEditView = false
      // don't delete current comment if it's not edited
      if (this.editItem) {
        this.currentComment = ""
      }
      this.editItem = null
      this.targetItem = null
    },
    async deleteComment (item) {
      this.cancelEditOrReply()
      const payload = {
        comment_id: item.id
      }
      await deleteCallCommentApi(payload)
      const index = this.commentsList.indexOf(item)
      const newComment = this.commentsList[index]
      newComment.comment_text = ""
      newComment.is_deleted = true
      this.commentsList[index] = newComment
      this.cancelEditOrReply()
    },
    async replyToComment (item) {
      this.cancelEditOrReply()
      await this.sleep(100)
      this.parentMessage = item.comment_text
      this.selectedParentId = item.id
      this.targetItem = item
      this.showReplyOrEditView = true
      document.getElementById(this.getInputId).focus()
    },
    async initiateCommentEditing (item) {
      this.cancelEditOrReply()
      await this.sleep(100)
      this.showReplyOrEditView = true
      this.editItem = item
      this.currentComment = item.comment_text
      document.getElementById(this.getInputId).focus()
    },
    sortWithHierarchy (items) {
      const currentUserId = this.user.pk
      // Separate items into parents and children
      const parents = []
      const childrenMap = {}

      items.forEach(item => {
        item.isCurrentUser = item.commented_by_id === currentUserId
        if (item.parent_id) {
          item.isChildComment = true
          childrenMap[item.parent_id] = childrenMap[item.parent_id] || []
          childrenMap[item.parent_id].push(item)
        } else {
          item.isChildComment = false
          parents.push(item)
        }
      })
      parents.sort((a, b) => a.id - b.id)
      for (const parentId in childrenMap) {
        childrenMap[parentId].sort((a, b) => a.id - b.id)
      }

      return parents.flatMap(parent => [parent, ...(childrenMap[parent.id] || [])])
    },
    checkWhetherCommentEdited (createdAt, updatedAt) {
      const date1 = new Date(updatedAt)
      const date2 = new Date(createdAt)
      return (date1.getTime() - date2.getTime()) / 1000 >= 2
    },
    async editComment () {
      const payload = {
        comment_id: this.editItem.id,
        comment_text: this.currentComment
      }
      const data = await updateCallCommentApi(payload)
      const index = this.commentsList.indexOf(this.editItem)
      const newComment = data.data
      newComment.isCurrentUser = newComment.commented_by_id === this.user.pk
      newComment.isChildComment = !!newComment.parent_id
      newComment.is_edited = true
      this.commentsList[index] = newComment
      this.currentComment = ""
      this.targetItem = null
      this.cancelEditOrReply()
    },
    async createComment () {
      if (!this.currentComment) {
        return
      }
      if (this.editItem) {
        this.editComment()
        return
      }
      const payload = {
        parent_id: this.selectedParentId,
        media_type: this.mediaType,
        timestamp_in_media: this.formatTime(this.timestampInMedia),
        call_id: this.callId,
        comment_text: this.currentComment,
        call_url: window.location.href
      }

      const data = await createCallCommentApi(payload)
      const newComment = data.data
      newComment.is_edited = false
      if (this.targetItem) {
        newComment.isCurrentUser = newComment.commented_by_id === this.user.pk
        newComment.isChildComment = true
        const index = this.commentsList.indexOf(this.targetItem)
        // Insert the new item after the target item
        let idx = index + 1
        for (; idx < this.commentsList.length; idx++) {
          if (!this.commentsList[idx].isChildComment) {
            break
          }
        }
        this.commentsList.splice(idx, 0, newComment)
        // this.commentsList.push(comment)
      } else {
        newComment.isCurrentUser = newComment.commented_by_id === this.user.pk
        newComment.isChildComment = false
        this.commentsList.push(newComment)
      }
      this.currentComment = ""
      this.targetItem = null
      this.cancelEditOrReply()
    },
    async loadCallComments () {
      this.commentsLoading = true
      const params = {
        call_id: this.callId,
        media_type: this.mediaType
      }
      const data = await getCallCommentsApi(params)
      this.commentsList = data.data
      this.commentsList.forEach(item => {
        item.is_edited = this.checkWhetherCommentEdited(item.created_at, item.updated_at)
      })
      this.commentsList = this.sortWithHierarchy(this.commentsList)
      this.commentsLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>

.call-comments {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #FFFFFF;
    border-radius: 8px;
    overflow: hidden;
}

.comment-header {

}
.comment-box {
    overflow-y: auto;
    overflow-x: hidden;
}

.comment-input {
    background-color: #FFFFFF;
    border-top: 1px solid $slate10;
}
.custom-input {
    border: 0;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
}
.comment-box-body {
    height: 100%;
}
.child-class {
    margin-left: 40px;
}
.edit-reply{
    padding: 12px 16px;
    background-color: #2A2D521A;
}

.comment-preview {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &__title {
        font-size: 14px;
        font-weight: 700;
        line-height: 16.8px;
        color: #2A2D52;
    }

    &__subtitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        color: #555775;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.no-comment {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    color: #7F8197;
}
.no-comment-box {
    height: 100%;
}
.headline {
    font-size: 14px;
    font-weight: 700;
    line-height: 16.8px;
    color: #555775;
}

.single-comment {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
}
</style>
