<template>
  <div
    class="speaker-timeline mt-4"
  >
    <div
      id="speaker-timeline"
      v-for="( currentSpeakerTimelineBlocks, name) in speakerTimelineBlocksData"
      :key="name"
      class="mt-4"
    >
      <div class="speaker-name mb-1 ml-2"> {{ name }}</div>
      <b-progress height="4px">
        <div class="w-100 d-flex">
          <div
            v-for="(entry, index) in currentSpeakerTimelineBlocks"
            :key="index"
            :style="{width: `${entry[0]}%`}"
            :class="entry[1]? 'speaking-time': 'silent-time'"
          ></div>
        </div>
      </b-progress>
      <div class="vertical-indicator" :style="{left: `${videoProgress}%`}"></div>
      <div class="dot-indicator" :style="{left: `${videoProgress}%`}"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpeakerTimeline",
  data () {
    return {
    }
  },
  props: {
    videoMetaDataLoading: {
      type: Boolean
    },
    videoProgress: {
      type: Number
    },
    speakerTimelineBlocksData: {
      type: Object
    }
  },
  methods: {
    handleVideoProgressEvents () {
      const progressElement = document.getElementById("speaker-timeline")
      progressElement.addEventListener("click", (event) => {
        if (this.videoMetaDataLoading) {
          return
        }
        const rect = progressElement.getBoundingClientRect()
        const mouseX = event.clientX - rect.left
        const progressWidth = progressElement.clientWidth
        const clickPercentage = mouseX / progressWidth
        this.$emit("update-video-progress", clickPercentage)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.speaker-timeline{
  position: relative;
  cursor: pointer;

  .vertical-indicator {
    position: absolute;
    top: 24px;
    height: calc(100% - 22px);
    width: 2px;
    background-color: #35375B;

  }

  .dot-indicator {
    position: absolute;
    top: 16.5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid #35375B;
    margin-left: -3px;
  }

  .silent-time {
    background-color: #0000000F !important;
  }

  .speaking-time {
    background-color: #2A2D52 !important;
  }
  .speaker-name {
    font-size: 12px;
    line-height: 14.4px;
    color: #2B2727;
  }
}

</style>
