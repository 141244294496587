<template>
  <div
    v-if="!!isMeetingEnded"
    :class="getContainerClass"
  >
    <!-- Video player -->
    <div
      v-if="showVideoPlayer"
      class="call-video"
    >
      <bao-video-player
        v-if="!!videoUrl"
        ref="videoPlayer"
        :is-open-from-external-call-share="isOpenFromExternalCallShare"
        :callId="callId"
        @video-time-changed="currentTime = $event"
      />

      <div v-else>
        <ProcessSpinner
          :processingMessage="staticText.meetingVideoProcessingMessage"/>
      </div>
    </div>

    <!-- Summary and Transcript -->
    <div class="call-transcripts">
      <b-tabs
        v-model="activeTabIndex"
        active-nav-item-class="border-left-0 border-top-0 border-right-0"
        nav-class="no-border d-flex flex-wrap"
      >
        <template
          v-if="showSaveToCrmToggleComputed"
          slot="tabs-end"
        >
          <div class="save-to-crm">
            <bao-toggle-button
              id="save-to-crm-toggle"
              :isActive="shouldSaveAISummaryToCrm"
              :label="staticText.saveToCRMLabel"
              :isDisabled="isCallSavedToCrm"
              button-class="minBtn font-14"
              @toggle="toggleSaveAISummaryToCrm"
            ></bao-toggle-button>
          </div>
        </template>
        <!-- video summary tab   -->
        <b-tab
          :title="staticText.summaryTabTitle"
          :class="['tab-content border-radius-tl-0', { 'border-tr-0' : showSaveToCrmToggleComputed }]"
        >
          <template slot="title">
            <div class="d-flex align-items-center">
              <div class="mr-1">{{staticText.summaryTabTitle}}</div>
              <bao-copy-button
                v-if="meetingSummaryAvailable"
                ref="baoCopySummaryButton"
                :shouldInitiateCopyProcess="true"
                @initiate-copy-process="copyTranscriptSummary"
              />
            </div>
          </template>
          <bao-video-summary
            v-if="meetingSummaryAvailable"
            ref="meeting-summary"
            class="tab-content__container tab-content__summary"
            :is-open-from-external-call-share="isOpenFromExternalCallShare"
            @show-copy-success="showCopySuccess"
            @copy-text="copyText"
          />
          <div v-else-if="isMeetingDataProcessingDone">
            {{ staticText.noSummaryAvailableText }}
          </div>
          <div v-else>
            <ProcessSpinner
              :processingMessage="staticText.meetingSummaryProcessingMessage"
            />
          </div>
        </b-tab>
        <!-- transcript  -->
        <b-tab
          v-if="!isOpenFromCallViewSummary"
          :title="staticText.transcriptTabTitle"
          class="tab-content"
        >
          <template slot="title">
            <div class="d-flex align-items-center">
              <div class="mr-1">{{staticText.transcriptTabTitle}}</div>
              <bao-copy-button
                v-if="meetingTranscript.length"
                ref="baoCopyTranscriptButton"
                :shouldInitiateCopyProcess="true"
                @initiate-copy-process="copyTranscript"
              />
            </div>
          </template>
          <div v-if="!!meetingTranscript.length"
               class="tab-content__transcript"
               ref="meeting-transcript"
          >
            <bao-video-transcript
              :meetingTranscript="meetingTranscript"
              :currentTime="currentTime"
              @update-time="seekVideo"
            />
          </div>
          <div v-else-if="isMeetingDataProcessingDone">
            {{ staticText.noTranscriptAvailableText }}
          </div>
          <div v-else>
            <ProcessSpinner
              :processingMessage="staticText.meetingTranscriptProcessingMessage"/>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
  <div v-else>
    <ProcessSpinner
      :processingMessage="staticText.meetingInProgressMessage"
    />
  </div>
</template>

<script>
import BaoVideoPlayer from "./BaoVideoComponents/BaoVideoPlayer.vue"
import BaoVideoTranscript from "./BaoVideoComponents/BaoVideoTranscript.vue"
import BaoToggleButton from "@/apps/base/BaoToggleButton"
import BaoCopyButton from "@/apps/base/BaoCopyButton"
import ProcessSpinner from "@/apps/base/ProcessSpinner"
import BaoVideoSummary from "./BaoVideoComponents/BaoVideoSummary.vue"
import { mapGetters, mapActions } from "vuex"

export default {
  name: "BaoVideo",
  components: {
    BaoToggleButton,
    BaoVideoPlayer,
    BaoCopyButton,
    BaoVideoTranscript,
    ProcessSpinner,
    BaoVideoSummary
  },
  data () {
    return {
      activeTabIndex: 0,
      fetchAudio: false,
      fetchTranscripts: false,
      fetchSummary: false,
      isMeetingDataProcessingDone: false,
      currentTime: 0,
      seekTime: 0,
      currentSummaryTab: "default",
      staticTextDefault: {
        transcriptTabTitle: "AI Transcript",
        meetingVideoProcessingMessage: "The video for this meeting is currently being processed. The data should usually be available in a few seconds, but it can take a while for long calls. Please wait, the page will be updated automatically.",
        meetingSummaryProcessingMessage: "The summary for this meeting is currently being processed. The data should usually be available in a few seconds, but it can take a while for long calls. Please wait, the page will be updated automatically.",
        meetingTranscriptProcessingMessage: "The transcript for this meeting is currently being processed. The data should usually be available in a few seconds, but it can take a while for long calls. Please wait, the page will be updated automatically.",
        meetingInProgressMessage: "The connected video meeting is still in progress. The data will be available once the meeting ends. Please wait, the page will be updated automatically.",
        summaryTabTitle: "AI Summary",
        noSummaryAvailableText: "Sadly no summary available for this call.",
        noTranscriptAvailableText: "Sadly no transcript available for this call.",
        saveToCRMLabel: "Save to CRM"
      }
    }
  },
  props: {
    isOpenFromCallViewSummary: {
      default: false
    },
    callId: {
      required: false
    },
    showVideoPlayer: {
      default: true
    },
    showSaveToCrmToggle: {
      type: Boolean
    },
    shouldSaveAISummaryToCrm: {
      type: Boolean,
      default: true
    },
    isCallSavedToCrm: {
      type: Boolean,
      default: false
    },
    isOpenFromExternalCallShare: {
      default: false
    }
  },
  mounted () {
    if (!this.isOpenFromExternalCallShare) this.loadSummaryData()
  },
  computed: {
    ...mapGetters({
      isMeetingEnded: "callVideoStore/isMeetingEnded",
      meetingSummary: "callVideoStore/getMeetingSummary",
      meetingTranscript: "callVideoStore/getMeetingTranscript",
      videoUrl: "callVideoStore/getVideoUrl"
    }),
    meetingSummaryAvailable () {
      return Object.keys(this.meetingSummary).length !== 0
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    showSaveToCrmToggleComputed () {
      return this.showSaveToCrmToggle && this.meetingSummary && this.activeTabIndex === 0 // Index 0 is for summary tab
    },
    getContainerClass () {
      const defaultClasses = "call-container"
      let additionalClasses = ""
      if (!this.isOpenFromCallViewSummary && !this.isOpenFromExternalCallShare) additionalClasses += " p-3"
      if (this.isOpenFromExternalCallShare) additionalClasses += " grid-display"
      return `${defaultClasses} ${additionalClasses}`
    }
  },
  methods: {
    ...mapActions({
      setCurrentSummaryText: "callSummaryStore/setCurrentSummaryText",
      fetchCallMeetingData: "callVideoStore/fetchCallMeetingData"
    }),
    loadSummaryData () {
      // reset the current summary if set already
      this.setCurrentSummaryText({ summaryText: "" })
      this.checkAndUpdateMeetingData()
      this.intervalForRefreshingMeetingData = setInterval(this.checkAndUpdateMeetingData, 5000)
    },
    checkAndUpdateMeetingData () {
      if (!this.isMeetingEnded || !Object.keys(this.meetingSummary).length) {
        this.updateMeetingData()
      } else {
        this.isMeetingDataProcessingDone = true
        clearInterval(this.intervalForRefreshingMeetingData)
        this.$refs["meeting-summary"].setCurrentSummary()
      }
    },
    toggleSaveAISummaryToCrm () {
      this.$emit("toggleSaveAISummaryToCrm")
    },
    copyTranscript () {
      const textToCopy = this.$refs["meeting-transcript"].innerText
      this.$refs.baoCopyTranscriptButton.copyText(textToCopy)
    },
    async copyTranscriptSummary () {
      this.$refs["meeting-summary"].copyTranscriptSummary()
    },
    async updateMeetingData () {
      await this.fetchCallMeetingData({ callId: this.callId })
    },
    seekVideo (timeInSeconds) {
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.seekTo(timeInSeconds)
      }
    },
    showCopySuccess () {
      this.$refs.baoCopySummaryButton.flashCopiedMessage()
    },
    copyText (textToCopy) {
      this.$refs.baoCopySummaryButton.copyText(textToCopy)
    }
  },
  beforeDestroy () {
    clearInterval(this.intervalForRefreshingMeetingData)
  }
}
</script>

<style scoped lang="scss">

.tab-content {
  background: #FFF;
  border-radius: 16px;
  padding: 16px 8px 16px 16px;

  .border-radius-tl-0 {
    border-radius: 0 16px 16px;
    @include media-breakpoint-down(xs) {
      &.border-tr-0 {
        border-radius: 0 0 16px 16px;
      }
    }
  }
}

.call-container  {
  :deep(.nav-link.active) {
    border-radius: 16px 16px 0 0;
  }

  .call-video {
    margin-bottom: 24px;
  }

  &.grid-display {

    .call-video,
    .call-transcripts {
      padding: 14px 16px;
      margin: 0;
    }

    .call-video {
      border-bottom: 1px solid $slate06;
    }

    @include media-breakpoint-up(xl) {
      display: flex;

      .call-video,
      .call-transcripts {
        width: 50%;
        padding: 16px;
      }

      .call-video {
        border-bottom: unset;
        border-right: 1px solid $slate06;
      }
    }
  }
}

.save-to-crm {
  display: flex;
  margin-left: auto;
  @include media-breakpoint-down(xs) {
    padding: 10px 0 10px 16px;
    width: 100%;
    background-color: white;
    border-radius: 0 16px 0 0;
  }
}

.regenerate-btn {
  //over write some styles of regenerate button
  padding: 8px 10px;
  line-height: 14px;
  svg {
    height: 16px;
    width: 16px;
  }
}

</style>
