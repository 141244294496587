<template>
  <div class="filter-select-202209161357 w-100" :class="{ 'error-class': hasError }">
    <div class="w-100">
      <label
        :for="labelTag"
        :class="['filter-label', { 'fixed-label' : hasFixedLabel }]">
        {{ label }}
        <span
          v-if="required"
          class="required-icon"
        >*</span>
      </label>
      <slot/>
    </div>
    <p v-if="hasError"
       class="filter-error-text"
    >
      {{ filterErrorMessage }}
    </p>
  </div>
</template>

<script>
export default {
  name: "BaoWidgetFilterContainer",
  data () {
    return {
      staticTextDefault: {
        defaultErrorMessage: "Please, select a value before saving changes."
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    filterErrorMessage () {
      return this.errorMessage ? this.errorMessage : this.staticText.defaultErrorMessage
    }
  },
  props: {
    label: {
      required: false,
      type: String,
      default: ""
    },
    labelTag: {
      required: false,
      type: String,
      default: ""
    },
    required: {
      required: false,
      type: Boolean,
      default: false
    },
    hasError: {
      required: false,
      type: Boolean,
      default: false
    },
    errorMessage: {
      required: false,
      default: "",
      type: String
    },
    hasFixedLabel: {
      required: false,
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.filter-select-202209161357 {
  position: relative;

  .filter-label {
    position: absolute;
    top: 8px;
    left: 0;
    padding-left: 16px;
    z-index: 3;
    font-size: 12px;
    line-height: 14px;
    margin: 0;
    color: $slate60;

    &.fixed-label {
      top: -4px;
      padding-top: 8px;
      width: 100%;
      background: #FDFCFC;
      border-radius: 8px 8px 0 0;
    }
  }

  .multiselect--active {
    z-index: unset;
  }

  &.error-class {
    .multiselect__tags {
      border: 1px solid rgba(188, 0, 0, 0.8) !important;
    }
  }

  .multiselect__tags {
    cursor: pointer;
    padding-top: 16px !important;
    border: 1px solid $slate20 !important;
    overflow: hidden;

    input {
      margin-left: unset !important;
    }
  }

  .multiselect__single,
  .option__small,
  .multiselect__placeholder {
    padding-left: 8px !important;
  }

  .multiselect__spinner {
    top: 10px;
    right: 4px;
  }
}
</style>
