
import axios from "axios"

const commentBaseUrl = `/api/call_comment`

export const updateCallsRealTagApi = async (payload) => {
  const apiUrl = "/api/calls/toggle_call_real/"
  const data = await axios.post(apiUrl, payload)
  return data
}

export const createCallCommentApi = async (payload) => {
  const apiUrl = `${commentBaseUrl}/create_call_comment`
  const data = await axios.post(apiUrl, payload)
  return data
}

export const updateCallCommentApi = async (payload) => {
  const apiUrl = `${commentBaseUrl}/update_call_comment/`
  const data = await axios.post(apiUrl, payload)
  return data
}

export const deleteCallCommentApi = async (payload) => {
  const apiUrl = `${commentBaseUrl}/delete_call_comment/`
  const data = await axios.post(apiUrl, payload)
  return data
}

export const getCallCommentsApi = async (params) => {
  const apiUrl = `${commentBaseUrl}/get_call_comments/`
  const data = await axios.get(apiUrl, { params })
  return data
}
